import React, { Component } from 'react'

import './index-footer.styl'

import Footer from 'client/shared/blocks/footer'

export default class IndexFooter extends Component {
  render(): JSX.Element {
    return (
      <div className="index-footer">
        <Footer stage="main_landing_footer" />
      </div>
    )
  }
}
