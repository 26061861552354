import React, { Component } from 'react'
import repeat from 'lodash/repeat'

import './index-layout-reviews.styl'

import reviewsRu from 'client/bookmate/assets/data/landing/reviews/reviews_ru.json'
import reviewsEn from 'client/bookmate/assets/data/landing/reviews/reviews_en.json'
import reviewsEs from 'client/bookmate/assets/data/landing/reviews/reviews_es.json'
import reviewsId from 'client/bookmate/assets/data/landing/reviews/reviews_id.json'
import reviewsDa from 'client/bookmate/assets/data/landing/reviews/reviews_da.json'
import reviewsNl from 'client/bookmate/assets/data/landing/reviews/reviews_nl.json'

import Carousel from 'client/shared/blocks/carousel'

type Props = { locale?: string }

export default class IndexLayoutReviews extends Component<Props> {
  getReviews(
    locale: string,
  ): { rating: number; text: string; author: string }[] {
    switch (locale) {
      case 'ru':
        return reviewsRu
      case 'es':
        return reviewsEs
      case 'id':
        return reviewsId
      case 'da':
        return reviewsDa
      case 'nl':
        return reviewsNl
      default:
        return reviewsEn
    }
  }

  render(): JSX.Element {
    const { locale } = this.props
    const reviews = this.getReviews(locale)

    return (
      <div className="index-layout-reviews">
        <Carousel>
          {reviews.map((review, index) => {
            return (
              <div className="index-layout-reviews__item" key={index}>
                <span className="index-layout-reviews__rating index-layout-reviews__rating_active">
                  {repeat('★ ', review.rating)}
                </span>
                {review.rating < 5 && (
                  <span className="index-layout-reviews__rating">
                    {repeat('★ ', 5 - review.rating)}
                  </span>
                )}
                <div className="index-layout-reviews__text">{review.text}</div>
                <div className="index-layout-reviews__author">
                  {review.author}
                </div>
              </div>
            )
          })}
        </Carousel>
      </div>
    )
  }
}
