import React, { Component } from 'react'
import { Trans } from '@lingui/react'

import './index-layout-reader.styl'

import { IndexSection } from 'client/bookmate/blocks/index-section'
import IndexLayoutScreen from '../index-layout-screen'

export default class IndexLayoutReader extends Component {
  render(): JSX.Element {
    return (
      <IndexSection type="flex">
        <IndexLayoutScreen kind="reader" />
        <div className="index-layout-reader__content">
          <h3 className="index-layout-reader__title">
            <Trans id="landing.reader_title" />
          </h3>
          <div className="index-layout-reader__text">
            <Trans id="landing.reader_text" />
          </div>
        </div>
      </IndexSection>
    )
  }
}
