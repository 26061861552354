import React, { Component } from 'react'
import { Trans } from '@lingui/react'

import { IndexSection } from 'client/bookmate/blocks/index-section'

import './index-layout-features.styl'

type Props = {
  kind?: 'premium' | 'audio'
}

export class IndexLayoutFeatures extends Component<Props> {
  static defautProps = {
    kind: 'premium',
  }

  render(): JSX.Element {
    const { kind } = this.props

    return (
      <IndexSection bgColor="#f3f6f9">
        <div className="index-layout-features">
          <div className="index-layout-features__content">
            <div className="index-layout-features__emoji index-layout-features__emoji_1" />
            <div className="index-layout-features__emoji index-layout-features__emoji_2" />
            <div className="index-layout-features__emoji index-layout-features__emoji_3" />
            <h3 className="index-layout-features__title">
              {kind === 'audio' ? (
                <Trans id="landing.offline_title_audio" />
              ) : (
                <Trans id="landing.offline_title" />
              )}
            </h3>
            <div className="index-layout-features__text">
              {kind === 'premium' ? (
                <Trans id="landing.offline_text" />
              ) : (
                <Trans id="landing.offline_text_audio" />
              )}
            </div>
          </div>
          <div className="index-layout-features__content">
            <div className="index-layout-features__emoji index-layout-features__emoji_4" />
            <div className="index-layout-features__emoji index-layout-features__emoji_5" />
            <h3 className="index-layout-features__title">
              <Trans id="landing.sync_title" />
            </h3>
            <div className="index-layout-features__text">
              {kind === 'premium' ? (
                <Trans id="landing.sync_text" />
              ) : (
                <Trans id="landing.sync_text_audio" />
              )}
            </div>
          </div>
        </div>
      </IndexSection>
    )
  }
}
