import React, { Component } from 'react'
import { Trans } from '@lingui/react'

import './index-layout-feed.styl'

import { IndexSection } from 'client/bookmate/blocks/index-section'
import { EmotionsCarousel } from 'client/bookmate/blocks/emotions-carousel'
import IndexLayoutScreen from '../index-layout-screen'

type Props = {
  kind: 'premium' | 'audio'
  button?: React.ReactNode
}

class IndexLayoutFeed extends Component<Props> {
  static defaultProps = {
    kind: 'premium',
  }

  render(): JSX.Element {
    const { kind, button } = this.props

    return (
      <IndexSection type="flex" bgColor="#fbeeea">
        <IndexLayoutScreen kind={kind === 'audio' ? 'feed_audio' : 'feed'} />
        <div className="index-layout-feed__content">
          <EmotionsCarousel />
          <h3 className="index-layout-feed__title">
            <Trans id="landing.feed_title" />
          </h3>
          <div className="index-layout-feed__text">
            <Trans
              id={`landing.${
                kind === 'premium' ? 'feed_text' : 'feed_text_audio'
              }`}
            />
          </div>
          {button}
        </div>
      </IndexSection>
    )
  }
}

export default IndexLayoutFeed
