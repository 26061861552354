import React from 'react'
import SVGInline from 'react-svg-inline'
import LogoIcon from './logo.svg'
import LanguagesButtonBox from 'client/shared/boxes/languages-button-box'

import './mtn-header.styl'

export const MTNHeader: React.FC = () => {
  return (
    <div className="header-mtn">
      <div className="header-mtn__container">
        <div className="header-mtn__logo">
          <a href="/mtn">
            <SVGInline svg={LogoIcon} />
          </a>
        </div>
        <div className="header-mtn__languages">
          <LanguagesButtonBox />
        </div>
      </div>
    </div>
  )
}
