import React, { Component } from 'react'
import { Trans } from '@lingui/react'
import without from 'lodash/without'
import config from 'config'

import { readingLanguagesByCountry } from 'client/shared/helpers/locales-helpers'

type Props = {
  country: string
}

const NUMBER_OF_LANGUAGES = without(config.locales, 'pt', 'vi').length // except for locales available only to Bookmate team

export function LanguagesListCount(props: Props) {
  return NUMBER_OF_LANGUAGES - readingLanguagesByCountry(props.country).length
}

class LanguagesList extends Component<Props> {
  render() {
    const { country } = this.props

    return (
      <span className="languages-list">
        {readingLanguagesByCountry(country).map((lang, index, arr) => {
          return (
            <span key={index}>
              <Trans id={`shared.${lang}_lang`} />
              {index + 1 !== arr.length && <span>,&nbsp;</span>}
            </span>
          )
        })}
      </span>
    )
  }
}

export default LanguagesList
