import React, { Component } from 'react'
import SVGInline from 'react-svg-inline'
import cn from 'classnames'

import './index-layout-screen.styl'

import uiBox from 'client/shared/decorators/ui-box'
import deviceHelper from 'shared/tools/device-helper'

import { State as AppState } from 'client/shared/reducers/app-reducer'

import PhoneImage from 'client/shared/assets/landing/phone.svg'
import PhoneImageMob from 'client/shared/assets/landing/phone_mob.svg'
import PhoneImageMobReader from 'client/shared/assets/landing/phone_reader_mob.svg'

const resolveImage = require.context(
  'client/shared/assets/landing/screens/',
  true,
  /\.png$/,
)

interface Props {
  kind:
    | 'library'
    | 'free'
    | 'audio'
    | 'reader'
    | 'media'
    | 'feed'
    | 'feed_audio'
    | 'library_audio'
    | 'shelves_audio'
    | 'player'
  locale: string
  app: AppState
}

class IndexLayoutScreen extends Component<Props> {
  getBackground(isMobile: boolean): string {
    const { kind } = this.props

    if (!isMobile) {
      return PhoneImage
    } else if (kind === 'reader') {
      return PhoneImageMobReader
    } else {
      return PhoneImageMob
    }
  }

  getImagePath(kind: string, isMobile: boolean, locale?: string): string {
    const filename = `screen_${kind}${isMobile ? '_mob' : ''}`

    try {
      return resolveImage(`./${locale}/${filename}.png`)
    } catch (err) {
      return resolveImage(`./en/${filename}.png`)
    }
  }

  render(): JSX.Element {
    const { kind, locale, app } = this.props
    const isMobile = deviceHelper.isMobileSize(app)

    return locale === 'ru' ? (
      <div className="index-layout-new-device__wrapper">
        <img
          className="index-layout-new-device__device"
          src="https://a.bmstatic.com/iu/100/192/device-new-6aff715eab66d3e07574ebbe40853182.png"
          alt=""
        />
        <img
          className="index-layout-new-device__content"
          src={this.getImagePath(kind, isMobile, 'ru')}
          alt=""
        />
      </div>
    ) : (
      <div
        className={cn('index-layout-screen', {
          'index-layout-screen_reader': kind === 'reader',
        })}
      >
        <SVGInline
          className="index-layout-screen__background"
          svg={this.getBackground(isMobile)}
        />
        <img
          className="index-layout-screen__shot"
          src={this.getImagePath(kind, isMobile, locale)}
          width="254"
          height={isMobile ? 330 : 453}
          alt=""
        />
      </div>
    )
  }
}

export default uiBox(IndexLayoutScreen)
