import React, { Component } from 'react'
import { Trans } from '@lingui/react'
import SVGInline from 'react-svg-inline'
import uiBox from 'client/shared/decorators/ui-box'
import deviceHelper from 'shared/tools/device-helper'
import { IndexSection } from 'client/bookmate/blocks/index-section'
import Arrow from './arrow.svg'
import ArrowMob from './arrow_mob.svg'
import { State as AppState } from 'client/shared/reducers/app-reducer'

import './index-layout-upload.styl'

type Props = {
  app: AppState
}

class _IndexLayoutUpload extends Component<Props> {
  render(): JSX.Element {
    return (
      <IndexSection bgColor="#f3f6f9">
        <div className="index-layout-upload__diagram">
          <div className="index-layout-upload__step">
            <span className="index-layout-upload__step-content">
              <Trans id="landing.upload_formats" />
            </span>
          </div>
          {this.renderArrow('left')}
          <div className="index-layout-upload__step index-layout-upload__step_second"></div>
          {this.renderArrow('right')}
          <div className="index-layout-upload__step">
            <span className="index-layout-upload__step-content">
              <Trans id="landing.upload_books" />
            </span>
          </div>
        </div>
        <div className="index-layout-upload__content">
          <h3 className="index-layout-upload__title">
            <Trans id="landing.upload_title" />
          </h3>
          <div className="index-layout-upload__text">
            <Trans id="landing.upload_text" />
          </div>
        </div>
      </IndexSection>
    )
  }

  renderArrow(kind) {
    const isMobile = deviceHelper.isMobileSize(this.props.app)
    const kindMod = kind ? `index-layout-upload__arrow_${kind}` : ''

    return (
      <SVGInline
        svg={isMobile ? ArrowMob : Arrow}
        className={`index-layout-upload__arrow ${kindMod}`}
      />
    )
  }
}

export const IndexLayoutUpload = uiBox(_IndexLayoutUpload)
