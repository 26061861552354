import React, { Component } from 'react'

import './index-section.styl'

type Props = {
  type?: string
  bgColor?: string
}

export class IndexSection extends Component<Props> {
  render(): JSX.Element {
    const { type, bgColor } = this.props

    const typeMod = type ? `index-section_${type}` : ''

    return (
      <section
        className={`index-section ${typeMod}`}
        style={{ backgroundColor: bgColor }}
      >
        <div className="index-section__wrapper">{this.props.children}</div>
      </section>
    )
  }
}
