import React, { Component } from 'react'
import { Trans } from '@lingui/react'
import uiBox from 'client/shared/decorators/ui-box'
import deviceHelper from 'shared/tools/device-helper'
import { State as AppState } from 'client/shared/reducers/app-reducer'
import { IndexSection } from 'client/bookmate/blocks/index-section'
import IndexLayoutScreen from '../index-layout-screen'
import { Locale } from 'client/shared/types/current-user'
import './index-layout-media.styl'

type Props = { app: AppState; kind: string; locale: Locale }

class _IndexLayoutMedia extends Component<Props> {
  static defaultProps = {
    kind: 'premium',
  }
  render(): JSX.Element {
    const { app, kind } = this.props
    const isMobile = deviceHelper.isMobileSize(app)

    return (
      <IndexSection type="mirrored">
        <IndexLayoutScreen
          kind={kind === 'audio' ? 'shelves_audio' : 'media'}
        />
        {isMobile && this.renderLogos()}
        <div className="index-layout-media__content">
          <h3 className="index-layout-media__title">
            {kind === 'premium' ? (
              <Trans id="landing.shelves_title" />
            ) : (
              <Trans id="landing.shelves_title_audio" />
            )}
          </h3>
          <div className="index-layout-media__text">
            {kind === 'premium' ? (
              <Trans id="landing.shelves_text" />
            ) : (
              <Trans id="landing.shelves_text_audio" />
            )}
          </div>
          {!isMobile && this.renderLogos()}
        </div>
      </IndexSection>
    )
  }

  renderLogos() {
    const { locale } = this.props
    let mediaLocale = ''

    switch (locale) {
      case 'ru':
        mediaLocale = 'ru'
        break
      case 'es':
        mediaLocale = 'es'
        break
      default:
        mediaLocale = 'en'
    }

    return (
      <div className="index-layout-media__logos">
        <img
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          src={`${ASSETS_PATH}landing/media-logos/media-logos_${mediaLocale}${
            mediaLocale === 'ru' ? '(v2)' : ''
          }.png`}
          alt=""
          width="984"
          height="60"
        />
      </div>
    )
  }
}

export const IndexLayoutMedia = uiBox(_IndexLayoutMedia)
