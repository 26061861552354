import React, { Component } from 'react'
import { Trans } from '@lingui/react'

import './index-layout-library.styl'

import { IndexSection } from 'client/bookmate/blocks/index-section'
import IndexLayoutScreen from '../index-layout-screen'
import LanguagesList, {
  LanguagesListCount,
} from 'client/shared/blocks/languages-list'

type Kind = 'premium' | 'audio'

type Props = {
  kind: Kind
  country: string
  button?: React.ReactNode
  customHeader?: string
  customDescription?: string
}

class IndexLayoutLibrary extends Component<Props> {
  static defaultProps = {
    kind: 'premium',
  }

  renderDescription(
    customDescription: string,
    kind: Kind,
    country: string,
  ): React.ReactNode {
    if (customDescription) {
      return customDescription
    } else if (kind === 'premium') {
      return (
        <Trans
          id="landing.library_text"
          components={[
            <LanguagesList country={country} />,
            <LanguagesListCount country={country} />,
          ]}
        />
      )
    } else {
      return (
        <Trans
          id="landing.library_text_audio"
          components={[<LanguagesListCount country={country} />]}
        />
      )
    }
  }

  render(): JSX.Element {
    const {
      kind,
      button,
      country,
      customHeader,
      customDescription,
    } = this.props

    return (
      <IndexSection type="flex">
        <IndexLayoutScreen
          kind={kind === 'audio' ? 'library_audio' : 'library'}
        />
        <div className="index-layout-library__content">
          <h3 className="index-layout-library__title">
            {customHeader || <Trans id="landing.library_title" />}
          </h3>
          <div className="index-layout-library__text">
            {this.renderDescription(customDescription, kind, country)}
            <br />
            <Trans id="landing.library_active_users" />
          </div>
          {button}
        </div>
      </IndexSection>
    )
  }
}

export default IndexLayoutLibrary
