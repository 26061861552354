import React, { Component } from 'react'
import { Trans } from '@lingui/react'
import SVGInline from 'react-svg-inline'

import './emotions-carousel.styl'

import HeartIcon from './heart.svg'

export class EmotionsCarousel extends Component {
  render(): JSX.Element {
    return (
      <div className="emotions-carousel">
        <div className="emotions-carousel__container" ref="container">
          <div className="emotions-carousel__emotion emotions-carousel__emotion_dart">
            <span className="emotions-carousel__icon">🎯</span>
            <Trans id="landing.emotion_dart" />
          </div>
          <div className="emotions-carousel__emotion emotions-carousel__emotion_thumbsup">
            <span className="emotions-carousel__icon emotions-carousel__icon_thumbsup">
              👍
            </span>
          </div>
          <div className="emotions-carousel__emotion emotions-carousel__emotion_zzz">
            <span className="emotions-carousel__icon">💤</span>
            <Trans id="landing.emotion_zzz" />
          </div>
          <div className="emotions-carousel__emotion emotions-carousel__emotion_heart">
            <SVGInline svg={HeartIcon} />
          </div>
          <div className="emotions-carousel__emotion emotions-carousel__emotion_rocket">
            <span className="emotions-carousel__icon">🚀</span>
            <Trans id="landing.emotion_rocket" />
          </div>
          <div className="emotions-carousel__emotion emotions-carousel__emotion_panda">
            <span className="emotions-carousel__icon">🐼</span>
            <Trans id="landing.emotion_panda" />
          </div>
          <div className="emotions-carousel__emotion emotions-carousel__emotion_droplet">
            <span className="emotions-carousel__icon">💧</span>
            <Trans id="landing.emotion_droplet" />
          </div>
        </div>
      </div>
    )
  }
}
