import React from 'react'
import Button from 'client/shared/blocks/button/button'

import './mtn-button.styl'

type Props = {
  disabled?: boolean
  loading?: boolean
  children: React.ReactNode
  onClick?: () => void
}

export const MTNButton: React.FC<Props> = props => (
  <Button {...props} className="mtn-button">
    {props.children}
  </Button>
)
