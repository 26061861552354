import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'lodash/fp'
import { State as RootState } from 'shared/types/redux'
import Spacer from 'client/shared/blocks/spacer'
import { Heading } from 'client/shared/blocks/heading/heading'
import { MTNButton } from 'client/bookmate/blocks/mtn/mtn-button'
import { MTNHeader } from 'client/bookmate/blocks/mtn/mtn-header'
import { PhoneInput } from 'client/bookmate/blocks/mtn/phone-input'
import IndexLayoutReviews from 'client/bookmate/blocks/index-layout/index-layout-reviews/index-layout-reviews'
import IndexLayoutFeed from 'client/bookmate/blocks/index-layout/index-layout-feed/index-layout-feed'
import IndexLayoutReader from 'client/bookmate/blocks/index-layout/index-layout-reader/index-layout-reader'
import IndexLayoutLibrary from 'client/bookmate/blocks/index-layout/index-layout-library/index-layout-library'
import { IndexLayoutUpload } from 'client/bookmate/blocks/index-layout/index-layout-upload/index-layout-upload'
import { IndexLayoutMedia } from 'client/bookmate/blocks/index-layout/index-layout-media/index-layout-media'
import { IndexLayoutFeatures } from 'client/bookmate/blocks/index-layout/index-layout-features/index-layout-features'
import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import {
  MNTShortSignInThunk,
  MTN_SET_PHONE_NUMBER,
  setMNTPhoneNumberAC,
  verifyPhone,
} from 'client/bookmate/reducers/mtn-reducer'
import { push } from 'react-router-redux'

import './mtn-layout.styl'
import { withRouter } from 'react-router'
import { Location, Router } from 'client/shared/types/react-router'

type Props = DecoratorProps & {
  isDesktop: boolean
  loading: boolean
  hideHeader?: boolean
  router: Router
  location: Location
  isAuth: boolean
}

type State = {
  phone: string
  valid: boolean
  areaCode: string
}

class _MTNLayout extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      phone: '',
      valid: false,
      areaCode: '',
    }
  }

  async componentDidMount() {
    if (typeof window !== 'undefined') {
      if (this.props.isAuth) this.props.dispatch(push('/'))

      const urlPhoneNumber = this.props.location.query.phone_number
      const urlMTNFlow = this.props.location.query.flow

      const cleanNumber = urlPhoneNumber
        ? urlPhoneNumber.replace(/\D/g, '').replace(/^233/, '+233')
        : null

      if (cleanNumber && /^\+233\d{9}$/.test(cleanNumber)) {
        this.setState(prev => ({
          ...prev,
          phone: cleanNumber.replace(/^\+233/, ''),
        }))

        await this.props.dispatch(setMNTPhoneNumberAC(cleanNumber))

        if (!this.props.isAuth)
          this.props.dispatch(
            MNTShortSignInThunk(
              cleanNumber,
              urlMTNFlow ? urlMTNFlow : undefined,
            ),
          )
      }
    }
  }

  handlePhoneChange = (v: string, isValid: boolean, areaCode: string): void => {
    this.setState({ phone: v, valid: isValid, areaCode })
  }

  handlePhoneSending = e => {
    e.preventDefault()
    const isMonth = this.props.location.pathname.includes('month')
    const { areaCode, phone } = this.state
    const phoneNumber = (areaCode + phone).slice(1) // removes + at the beginning
    // TODO: need refactoring
    this.props.dispatch({
      type: MTN_SET_PHONE_NUMBER,
      payload: { phoneNumber, isMonth },
    })
    this.props.dispatch(verifyPhone(phoneNumber))
  }

  renderLoginForm(): JSX.Element {
    const { loading } = this.props

    return (
      <form onSubmit={this.handlePhoneSending}>
        <PhoneInput
          value={this.state.phone}
          onChange={this.handlePhoneChange}
        />
        <Spacer />
        <MTNButton disabled={!this.state.valid} loading={loading}>
          Log in with MTN
        </MTNButton>
        <Spacer size={8} />
        <div className="join-label">Join Now and Start Reading!</div>
      </form>
    )
  }

  render(): JSX.Element {
    const { locale, country, hideHeader } = this.props
    return (
      <div className="mtn-layout">
        {hideHeader ? null : <MTNHeader />}
        <div className="mtn-lead-in">
          <div className="mtn-column">
            <Heading kind="graphik" className="mtn-heading">
              Immerse Yourself in a World of 250,000+ Books and Audiobooks
            </Heading>
            <Spacer size={16} />
            <p className="mtn-subheading">
              Gain instant access to an extensive range of bestsellers spanning
              every genre, from gripping thrillers to heartwarming romance and
              mind-bending sci-fi
            </p>
            <Spacer size={24} />
            <div className="form-wrapper">{this.renderLoginForm()}</div>
          </div>
        </div>
        <IndexLayoutLibrary
          country={country}
          customHeader="Access our library for just 25 GHS per month"
          customDescription={`
            Why buy just one book, when you can access thousands of them for
            the same price? Choose from our well-stocked collection of books
            – in 12 different languages.
          `}
        />
        <IndexLayoutUpload />
        <IndexLayoutReader />
        <IndexLayoutMedia />
        <IndexLayoutFeatures kind="premium" />
        <IndexLayoutFeed />
        <IndexLayoutReviews locale={locale} />
      </div>
    )
  }
}

const connectWrapper = connect((state: RootState) => ({
  loading: state.mtn.loading,
  isAuth: state.currentUser.auth,
}))

const wrapper = compose(uiBox, connectWrapper, withRouter)

export const MTNLayout = wrapper(_MTNLayout)
